import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Link } from './ui';
import Breadcrumbs from './Breadcrumbs';

const StyledPageHeader = styled.section``;

const StyledInner = styled.div`
  ${sectionMargins(undefined, '60px')};
  margin-right: auto;
  margin-left: auto;
  max-width: 600px;
  text-align: center;

  ${({ $isBlogAuthor }) => {
    if ($isBlogAuthor) {
      return css`
        max-width: 900px;
        margin-bottom: 50px;

        ${minBreakpointQuery.medium`
          margin-bottom: 80px;
        `}
      `;
    }
  }}
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: auto;
  max-width: 180px;
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    max-width: 220px;
  `}

  ${minBreakpointQuery.large`
    max-width: 260px;
  `}
`;

const StyledOverline = styled.p`
  margin-bottom: 12px;
  color: ${brandColours.primary};
  ${fontSize(12)};
  text-transform: uppercase;
  letter-spacing: 2px;

  ${minBreakpointQuery.small`
    margin-bottom: 16px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 20px;
  `}
`;

const StyledHeading = styled.h1`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
  ${fluidFontSize('35px', '55px', breakpointSizes.tiny, breakpointSizes.large)};
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const StyledText = styled.p`
  white-space: break-spaces;
  line-height: 1.4;
  margin-top: 12px;
  color: ${brandColours.primary};

  ${minBreakpointQuery.small`
    margin-top: 16px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 20px;
    ${fontSize(18)};
  `}
`;

const StyledLogo = styled.img`
  margin-top: 12px;
  max-height: 100px;
  max-width: 200px;

  ${minBreakpointQuery.small`
    margin-top: 16px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 20px;
  `}
`;

const PageHeader = ({
  overline,
  image,
  heading,
  text,
  author,
  logo,
  breadcrumbs,
  isBlogAuthor,
}) => (
  <StyledPageHeader>
    <Container>
      <Breadcrumbs breadcrumbs={breadcrumbs} currentPageTitle={heading} />
      <StyledInner $isBlogAuthor={isBlogAuthor}>
        {image && <StyledImage image={image.gatsbyImageData} alt={image.alt} />}
        {overline && <StyledOverline>{overline}</StyledOverline>}
        <StyledHeading>{heading}</StyledHeading>
        {(text || author) && (
          <StyledContent>
            {text && <StyledText>{text}</StyledText>}
            {author && (
              <Link to={`/blog/${author.slug}`}>
                <StyledText>{author.title}</StyledText>
              </Link>
            )}
          </StyledContent>
        )}
        {logo && <StyledLogo src={logo.url} alt={logo.alt} />}
      </StyledInner>
    </Container>
  </StyledPageHeader>
);

export default PageHeader;
