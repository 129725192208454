import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';

const ThanksPage = () => (
  <Layout>
    <HelmetDatoCms title="Thanks" />
    <main>
      <PageHeader heading="Thanks" text="We will be in touch soon!" />
    </main>
  </Layout>
);

export default ThanksPage;
